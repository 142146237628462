'use strict';

angular.module('kljDigitalLibraryApp')
    .controller('OrderTrackCtrl', ["$scope", "$uibModalInstance", "shipment", "$window",
        function($scope, $uibModalInstance, shipment, $window) {
            $scope.trackingNo = shipment.tracking_number;

            $scope.close = function() {
                console.log("no");
                $uibModalInstance.close();
            }

            $scope.trackOrder = function(flag) {
                $window.open('http://firstflight.net:8081/single-web-tracking/singleTracking.do', '_blank');
                $uibModalInstance.close("track");
            }

        }
    ]);